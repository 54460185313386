import { Button, IconBug, IconNetworkLost, IconNotAllowed, IconNotFound, Text } from '@newfront-insurance/core-ui';
import * as Sentry from '@sentry/nextjs';
import React from 'react';

import { createErrorBoundary } from './create';

export const PageErrorBoundary = createErrorBoundary({
  onError: (error) => Sentry.captureException(error),
  messages: {
    genericError: () => ({
      title: 'There was a problem loading this page',
      icon: <IconBug />,
      subtitle: (
        <Text textAlign="center">
          Try reloading the page to fix the issue. If you’re still experiencing this issue please contact your servicing
          team.
        </Text>
      ),
    }),
    notFoundError: () => ({
      title: 'The page you’re looking for doesn’t exist',
      icon: <IconNotFound />,
      subtitle: (
        <Text textAlign="center">
          Check the URL. If you think this is a mistake please contact your servicing team.
        </Text>
      ),
    }),
    permissionError: () => ({
      title: 'You don’t have permission to view this page',
      icon: <IconNotAllowed />,
      subtitle: <Text textAlign="center">If you think this is a mistake you can contact your servicing team.</Text>,
    }),
    networkError: () => ({
      title: 'There was a network connection issue that prevented this page from loading',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: <Text textAlign="center">Please check your internet connection and try again.</Text>,
    }),
    timeoutError: () => ({
      title: 'The page took too long to load',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: (
        <Text textAlign="center">
          Please check your internet connection and try again. If the issue persists please contact your servicing team.
        </Text>
      ),
    }),
    notLoggedInError: () => ({
      title: 'You need to be logged in to view this page',
      icon: <IconNotAllowed />,
      subtitle: <Text>If you think this is a mistake you can contact your servicing team.</Text>,
    }),
  },
});

export const ModalErrorBoundary = createErrorBoundary({
  onError: (error) => Sentry.captureException(error),
  messages: {
    genericError: () => ({
      title: 'There was a problem loading this content',
      icon: <IconBug />,
      subtitle: (
        <Text textAlign="center">
          Try reloading the page to fix the issue. If you’re still experiencing this issue please contact your servicing
          team.
        </Text>
      ),
    }),
    notFoundError: () => ({
      title: 'The content you’re looking for doesn’t exist',
      icon: <IconNotFound />,
      subtitle: <Text textAlign="center">If you think this is a mistake please contact your servicing team.</Text>,
    }),
    permissionError: () => ({
      title: 'You don’t have permission to view this content',
      icon: <IconNotAllowed />,
      subtitle: <Text textAlign="center">If you think this is a mistake you can contact your servicing team.</Text>,
    }),
    networkError: () => ({
      title: 'There was a network connection issue that prevented this content from loading',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: <Text textAlign="center">Please check your internet connection and try again.</Text>,
    }),
    timeoutError: () => ({
      title: 'The content took too long to load',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: (
        <Text textAlign="center">
          Please check your internet connection and try again. If the issue persists please contact your servicing team.
        </Text>
      ),
    }),
    notLoggedInError: () => ({
      title: 'You need to be logged in to view this content',
      icon: <IconNotAllowed />,
      subtitle: <Text>If you think this is a mistake you can contact your servicing team.</Text>,
    }),
  },
});

const ReloadButton: React.FC = () => (
  <Button size="secondary" onClick={() => window.location.reload()}>
    Try again
  </Button>
);
