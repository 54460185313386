import { createTRPCClient, useClientQueryHooks } from '@newfront-insurance/data-layer-client';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';

import { AuthProvider, AuthSwapProvider } from './auth';

import type { AppRouter } from '@/api/trpc';
import { getConfig } from '@/config';

const { ENABLE_PREVIEW_MODE } = getConfig();

export const RootTRPCProvider = createProvider(() => {
  const { getAccessTokenCallback } = useProvider(AuthProvider);
  const { router } = useRouter();

  const appClient = createTRPCClient<AppRouter>({
    url: `${router.basePath}/api/trpc`,
    getToken: getAccessTokenCallback,
    timeoutInMilliseconds: 30000,
  });

  return useClientQueryHooks(appClient);
});

export const SwapTRPCProvider = createProvider(() => {
  const { getSwappedAccessToken } = useProvider(AuthSwapProvider);
  const { router } = useRouter();

  const appClient = createTRPCClient<AppRouter>({
    url: `${router.basePath}/api/trpc`,
    getToken: getSwappedAccessToken,
    timeoutInMilliseconds: 30000,
  });

  return useClientQueryHooks(appClient);
});

/**
 * This provider will return the TRPC provider that is needed based
 * on whether we're using the swap token or not. Enabling preview mode
 * will use the root access token for the logged in user. When the auth
 * realm is set to use the employee login this means the access token used
 * for requests will have the employee scopes, allowing them to view any account
 * they have access to.
 */
export const TRPCProvider = createProvider(() => {
  const rootProvider = useProvider(RootTRPCProvider);
  const swapProvider = useProvider(SwapTRPCProvider);
  if (ENABLE_PREVIEW_MODE === true) {
    return rootProvider;
  }
  return swapProvider;
});
