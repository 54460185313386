/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-process-env */
import { LogoSpinner } from '@newfront-insurance/core-ui';
import type { AppProps } from 'next/app';
import React, { Suspense } from 'react';

import { PageErrorBoundary } from '@/client/components/error-boundary';
import { AllProviders } from '@/client/providers';

import '../client/global.css';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <PageErrorBoundary>
      <AllProviders>
        <Suspense fallback={<LogoSpinner />}>
          <Component {...pageProps} />
        </Suspense>
      </AllProviders>
    </PageErrorBoundary>
  );
}
